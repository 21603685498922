import {
  Box,
  Checkbox,
  Text,
  Tooltip,
  isTxString,
  parseTimestamp,
} from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import {
  MembershipInvitesForm,
  MembershipTypePeriod,
} from '../../../../../../models';
import { MembershipTypeSelector, useSelector } from '../../../../../../store';
import { Styled } from './select-periods.styled';

function SelectPeriodCheckboxText(props: { period: MembershipTypePeriod }) {
  const { period } = props;

  if (period.validTo && period.validFrom) {
    const validFrom = parseTimestamp(period.validFrom, 'DD MMM YYYY');
    const validTo = parseTimestamp(period.validTo, 'DD MMM YYYY');

    return <Text variant="bodySm" text={`${validFrom} - ${validTo}`} />;
  }

  return (
    <Text variant="bodySm" tx="label.memberships.invite-members.lifetime" />
  );
}

function SelectPeriodCheckbox(props: { period: MembershipTypePeriod }) {
  const { period } = props;

  const { formState, watch, setValue } =
    useFormContext<MembershipInvitesForm>();

  const membershipPeriodKeys = watch('membershipPeriodKeys');

  const isChecked = !!membershipPeriodKeys?.includes(period.key);

  const togglePeriod = () => {
    if (!membershipPeriodKeys) {
      setValue('membershipPeriodKeys', [period.key], {
        shouldValidate: formState.isSubmitted,
      });
      return;
    }

    const newState = isChecked
      ? membershipPeriodKeys.filter((key) => key !== period.key)
      : [...membershipPeriodKeys, period.key];

    setValue('membershipPeriodKeys', newState, {
      shouldValidate: formState.isSubmitted,
    });
  };

  return (
    <Box
      cursor="pointer"
      flex
      gap={16}
      onClick={togglePeriod}
      width="fit-content"
    >
      <Checkbox
        onChange={togglePeriod}
        checked={isChecked}
        value={period.key}
      />

      {period.name.length > 20 ? (
        <Box>
          <Tooltip placement="bottom" title={period.name}>
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              variant="bodyMd"
              text={period.name}
              textOverflow="ellipsis"
              maxWidth="20ch"
            />
          </Tooltip>

          <SelectPeriodCheckboxText period={period} />
        </Box>
      ) : (
        <Box>
          <Text
            whiteSpace="nowrap"
            overflow="hidden"
            variant="bodyMd"
            text={period.name}
            textOverflow="ellipsis"
            maxWidth="20ch"
          />

          <SelectPeriodCheckboxText period={period} />
        </Box>
      )}
    </Box>
  );
}

function renderSelectPeriodCheckbox(period: MembershipTypePeriod) {
  return <SelectPeriodCheckbox key={period.key} period={period} />;
}

export function SelectPeriods() {
  const periods = useSelector(MembershipTypeSelector.selectMembershipPeriods);
  const membershipType = useSelector(
    MembershipTypeSelector.selectMembershipType,
  );

  const { formState } = useFormContext<MembershipInvitesForm>();

  if (membershipType === 'standard') {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        tx="label.memberships.invite-members.select-periods"
        variant="bodyMdBold"
      />

      {!periods?.length ? (
        <Text
          variant="bodyMd"
          color="errorLabel"
          tx="errors.no-periods.empty"
        />
      ) : (
        <Styled.PickPeriodsGrid>
          {periods.map(renderSelectPeriodCheckbox)}
        </Styled.PickPeriodsGrid>
      )}

      {isTxString(formState.errors.membershipPeriodKeys?.message) && (
        <Text
          variant="bodyMd"
          color="errorLabel"
          tx={formState.errors.membershipPeriodKeys?.message}
        />
      )}
    </Box>
  );
}
