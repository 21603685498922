import {
  Box,
  ContentContainer,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  Radio,
  Text,
  parseTimestamp,
} from '@orbiapp/components';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import { TicketHistoryListItem } from '../../../../../models';
import {
  ActivityDataSelector,
  ActivitySelector,
  TicketsHistorySelector,
  useSelector,
} from '../../../../../store';
import { EventActions } from '../components';

interface TicketsHistoryItemProps extends TicketHistoryListItem {
  style: React.CSSProperties;
}

const TicketsHistoryItem = (props: TicketsHistoryItemProps) => {
  const {
    userFullName,
    ticketTypeName,
    ticketRefundedAt,
    purchaseCreatedAt,
    ticketEmailTransfer,
    style,
  } = props;

  return (
    <Box style={style} p={4} gap={4} flex>
      <Text
        text={parseTimestamp(purchaseCreatedAt, 'DD MMM YYYY HH:mm')}
        variant="bodySm"
        whiteSpace="nowrap"
      />

      {userFullName ? (
        <React.Fragment>
          <Text whiteSpace="nowrap" text={userFullName} variant="bodySm" />
          <Text
            tx="label.view-activity.ticket-history.purchased"
            variant="bodySm"
            whiteSpace="nowrap"
          />
          <Text whiteSpace="nowrap" text={ticketTypeName} variant="bodySm" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text
            tx="placeholder.deleted-user"
            variant="bodySm"
            whiteSpace="nowrap"
          />
          <Text
            tx="label.view-activity.ticket-history.purchased"
            variant="bodySm"
            whiteSpace="nowrap"
          />
          <Text whiteSpace="nowrap" text={ticketTypeName} variant="bodySm" />
        </React.Fragment>
      )}

      {ticketRefundedAt && (
        <React.Fragment>
          <Text
            tx="label.view-activity.ticket-history.refunded"
            variant="bodySmBold"
            whiteSpace="nowrap"
          />
          <Text
            text={parseTimestamp(ticketRefundedAt, 'DD MMM YYYY HH:mm')}
            variant="bodySmBold"
            whiteSpace="nowrap"
          />
        </React.Fragment>
      )}

      {ticketEmailTransfer && (
        <Text
          tx="label.view-activity.ticket-history.transferred"
          txArgs={{ email: ticketEmailTransfer.email }}
          variant="bodySmBold"
          whiteSpace="nowrap"
        />
      )}
    </Box>
  );
};

const ROW_HEIGHT = 24.8;

function TicketsHistoryContent() {
  const ticketsHistory = useSelector(TicketsHistorySelector.selectAll);
  const ticketsHistoryStatus = useSelector(TicketsHistorySelector.selectStatus);

  const [filter, setFilter] = React.useState<
    'all' | 'refunded' | 'transferred'
  >('all');

  const filteredTicketsHistory = React.useMemo(
    () =>
      filter === 'all'
        ? ticketsHistory
        : ticketsHistory.filter((ticketsHistoryItem) => {
            switch (filter) {
              case 'refunded':
                return !!ticketsHistoryItem.ticketRefundedAt;

              case 'transferred':
                return !!ticketsHistoryItem.ticketEmailTransfer;

              default:
                return true;
            }
          }),
    [ticketsHistory, filter],
  );

  const renderTicketsHistoryItem: ListRowRenderer = ({ key, index, style }) => {
    return (
      <TicketsHistoryItem
        style={style}
        key={key}
        {...filteredTicketsHistory[index]}
      />
    );
  };

  if (ticketsHistoryStatus !== 'pending' && !ticketsHistory.length) {
    return <EmptyState titleTx="placeholder.tickets-history-empty" />;
  }

  const setOnlyShowAll = () => setFilter('all');
  const setOnlyShowRefunded = () => setFilter('refunded');
  const setOnlyShowTransfered = () => setFilter('transferred');

  const refundsNotEmpty = ticketsHistory.some(
    (ticketsHistoryListItem) => ticketsHistoryListItem.ticketRefundedAt,
  );

  const ticketTransferNotEmpty = ticketsHistory.some(
    (ticketsHistoryListItem) => ticketsHistoryListItem.ticketEmailTransfer,
  );

  return (
    <Box flex flexDirection="column" gap={16}>
      {(refundsNotEmpty || ticketTransferNotEmpty) && (
        <Box flex flexWrap="wrap" gap={16}>
          <Radio
            checked={filter === 'all'}
            onChange={setOnlyShowAll}
            tx="label.view-activity.ticket-history.sort.all"
          />

          {refundsNotEmpty && (
            <Radio
              checked={filter === 'refunded'}
              onChange={setOnlyShowRefunded}
              tx="label.view-activity.ticket-history.sort.refunded"
            />
          )}

          {ticketTransferNotEmpty && (
            <Radio
              checked={filter === 'transferred'}
              onChange={setOnlyShowTransfered}
              tx="label.view-activity.ticket-history.sort.transferred"
            />
          )}
        </Box>
      )}

      <List
        containerStyle={{ width: '100%', maxWidth: '100%' }}
        style={{ width: '100%' }}
        width={1}
        height={filteredTicketsHistory.length * ROW_HEIGHT}
        rowCount={filteredTicketsHistory.length}
        rowHeight={ROW_HEIGHT}
        rowRenderer={renderTicketsHistoryItem}
      />
    </Box>
  );
}
export function ViewActivityTicketsHistory() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);

  if (!isActivityOwner) {
    return <Navigate to={`/activities/${activityKey}/description`} />;
  }

  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <Box flex flexWrap="wrap" gap={16} flexJustify="between">
            <Text
              as="h1"
              color="pageTitle"
              tx="label.view-activity.tabs.tickets-history"
              variant="titleMd"
            />

            <EventActions />
          </Box>

          <TicketsHistoryContent />
        </InnerContentContainer>
      </ContentContainer>
    </InnerPageContainer>
  );
}
